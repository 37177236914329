@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.action-container {
  display: flex;
  margin-bottom: 20px;
}

::v-deep table th {
  &.col-sku {
    width: 18%;
  }
  &.col-actions {
    width: 18%;
  }
}

// v-alert
.info-box-text {
  flex-grow: 1;
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
}

.errors-list {
  list-style-type: circle;
  padding-left: 2rem;

  li {
    padding-left: 1rem;
  }
}

.close-btn {
  position: absolute;
  top: var(--dialog-close-offset);
  right: var(--dialog-close-offset);
}
